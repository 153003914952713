<template>
  <el-card>
    <div class="day-task-list">
      <p><b>{{ dateList }}</b></p>
      <p><b>{{ total }}</b></p>
    </div>

    <div
      v-for="timeEntry of groupByTitle"
      :key="timeEntry.uuid"
    >
      <TimeEntry :time-entry="timeEntry" />
    </div>
  </el-card>
</template>

<script>
import { DateTime, Duration } from 'luxon';

export default {
  name: 'TimeEntriesList',
  components: {
    TimeEntry: () => import('./TimeEntry.vue'),
  },
  props: {
    dayItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    dateList() {
      const difference = DateTime.local().startOf('day').ts - DateTime.fromISO(this.dayItem.date).startOf('day').ts;

      if (difference === 0) {
        return 'Today';
      }

      if (difference === 86400000) {
        return 'Yesterday';
      }

      return DateTime.fromISO(this.dayItem.date).toFormat('LLL dd');
    },
    total() {
      const time = this.dayItem.entries.reduce((res, entry) => {
        const duration = DateTime.fromISO(entry.endTime).ts - DateTime.fromISO(entry.startTime).ts;

        return res + duration;
      }, 0);

      return Duration.fromMillis(time).toFormat('h:mm:ss');
    },
    groupByTitle() {
      const grouped = this.dayItem.entries.reduce((result, currentValue) => {
        (result[currentValue.title] = result[currentValue.title] || []).push(currentValue);

        return result;
      }, []);

      return Object.values(grouped)
        .map(item => (item.length === 1 ? item[0] : item))
        .sort((a, b) => {
          if (a.length) {
            return new Date(b.startTime) - new Date(a[a.length - 1].startTime);
          }

          if (b.length) {
            return new Date(b[b.length - 1].startTime) - new Date(a.startTime);
          }

          return new Date(b.startTime) - new Date(a.startTime);
        });
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
::v-deep {
  .el-card__body {
    padding: 0;
  }

  .el-input__inner {
    padding: 0;
    border: none;
  }
}

.day-task-list {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) min-content 60px;
  grid-gap: 20px;
  align-items: center;
  padding: 15px 25px;
}

.day-task-list p {
  font-size: 16px;
}

</style>
